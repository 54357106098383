export const Header_AboutMe = {
    id: "about",
    header: "ABOUT ME"
}

export const Header_Skills = {
    id: "skills",
    header: "SKILLS"
}

export const Header_Experience = {
    id: "experience",
    header: "EXPERIENCE"
}

export const Header_Projects = {
    id: "projects",
    header: "PROJECTS"
}

export const Header_Contact = {
    id: "contact",
    header: "CONTACT ME"
}

export const Header_Work = {
    id: "work",
    header: "WORK EXPERIENCE"
}

export const Header_Leadership = {
    id: "leadership",
    header: "LEADERSHIP EXPERIENCE"
}

