export const experience_Molli = {
    id: 'experience', 
    lightBg: true, 
    lightText: true,
    darkText: false,

    align_left: true, 
    title: 'May - Aug 2022', 
    topline: "MOLLI Surgical",

    headline: 'Research & Development Engineer', 
    description1: "Through working at MOLLI Surgical, I was able to get a lot of hands-on experience working with medical devices. My key project consisted of using Python to develop data-processing software in order to comprehensively characterize the accuracy of a surgical localization device.",
    description2: "I was also able to work on multiple projects for other various novel electromechanical devices, and contributed to the regulatory and design processes with the research and development team.",

    imgStart: false, // true -> img on right, false -> img on lefts
    img: "./../images/img-molli.jpeg", 
    alt: 'MOLLI', 

    buttonLabel: '', 
    buttonLink: ''
}

export const experience_Ford = {
    id: 'experience', 
    lightBg: false, 
    lightText: true,
    darkText: false,

    align_left: false, 
    title: 'Sept - Dec 2021', 
    topline: "Ford Motor Company of Canada",

    headline: 'Product Development Intern', 
    description1: "Going into my third work term, I had a much different experience of being a developer for a large company. I was assigned React tickets and had full ownership of the development process. I collaborated with other senior developers to solve these issues which are now currently implemented in the SYNC4 infotainment system in new Ford electric vehicles.",
    description2: "It was incredible to follow along the version control process and see my changes being implemented into the main software product. As an additional project, I also programmed a diagnostic data processing script that parses log files to plot changes in display brightness using Python.",
    // description1: "Controlled the development and oversaw the testing of multiple user interface and user experience tickets using React.", 
    // description2: "Successfully completed changes that are currently implemented in the infotainment system of the new line of Ford electric vehicles.", 
    // description3: "Programmed a diagnostic data processing script that parses log files to plot changes in display brightness using Python.", 

    imgStart: true, // true -> img on right, false -> img on left
    img: "./../images/img-ford.jpeg", 
    alt: 'Ford', 

    buttonLabel: '', 
    buttonLink: ''
}

export const experience_XSENSOR = {
    id: 'experience', 
    lightBg: true, 
    lightText: true,
    darkText: false,

    align_left: true, 
    title: 'Jan - Apr 2021', 
    topline: "XSENSOR Technology Corporation",

    headline: 'Machine Learning Intern', 
    description1: "Machine learning always interested me, and through XSENSOR, I learned about the complete process from data collection to testing. Specifically, I worked on the cleaning, post-processing and testing of a model’s prediction of a patient’s pressure injury risk.",
    description2: "The company was growing quick, so I spearheaded an initiative to outsource the data cleaning efforts to international vendors, and set up AWS environments to efficiently upscale the process.",
    description3: "With some extra time, I got to work on a fascinating project that focused on creating a contactless real-time heart rate detection system. Through integrating signal detection and filtering methods, I used Python to create a system with 94% accuracy.",
    // description3: "Oversaw data collection, cleaning, post-processing and testing that improved a machine learning models prediction of a patient’s risk of pressure injury.", // Collected, cleaned and processed pressure imaging data to improve a joint detection machine learning model.",
    // description2: "Led data cleaning outsourcing efforts, including vendor selection, negotiation of terms, design and delivery of training resources, and establishment of virtual workspace environments through AWS in order to efficiently upscale the process.", // "Efficiently managed the outsourcing of data cleaning by communicating with and developing training resources for international partners.",//"Executed Python code that created plots and extracted statistics for international client reports.",
    // description1: "Integrated signal detection and filtering to help create a contactless system that used Python to detect a patient’s real-time heart rate with an accuracy above 94%.", // "Assisted with the upscaling and transfer of the data collection and pipeline to Amazon Web Services.",

    imgStart: false, // true -> img on right, false -> img on left
    img: "./../images/img-x.jpg", 
    alt: 'XSENSOR', 

    buttonLabel: '', 
    buttonLink: ''
}

export const experience_BCI = {
    id: 'experience', // for navigation
    lightBg: false, // background color
    lightText: true, // color of 
    lightTextDesc: false,
    darkText: false,

    align_left: false, // alignment of title and topline
    title: "May - Aug 2020", // type of experience
    topline: "Alberta Children's Hospital", // time and location

    headline: 'Brain Computer Interface Research Student', // job title and bullet points:
    description1: "During my first work term, I worked with an incredible group at the Kirton Lab to derive and develop a unique brain computer interface (BCI) project. With the goal of assisting kids with neurological disabilities, I focused on prototyping a game that would allow for them to paint without any physical control.",
    description2: "This project used Python to analyze real-time EEG data and Node.js for controlling the robot to move based on extracted features. I then presented the project outcome at 2 symposiums and received an award for the best 3-minute thesis presentation.",
    // description1: 'Developed and prototyped a project that assists kids with neurological disabilities to paint without physical control.',
    // description2: 'Utilized Python to analyze real-time EEG data and Node.js for controlling a robot to move based on extracted features.',
    // description4: 'Presented the project outcome at 2 symposiums and received an award for the best 3-minute thesis presentation.',
    
    imgStart: true, // true -> img on right, false -> img on left
    img: "./../images/img-bci3.jpg", // image location
    alt: 'BCI', // alternate text instead of image

    buttonLabel: 'Github', // words on the button
    buttonLink: 'https://github.com/raziqkassam/sphero', // what href or external link the button will take you to

    buttonLabel2: "Presentation",
    buttonLink2: "https://www.youtube.com/watch?v=D6xbxsmxb2w"
}

export const experience_BioTEC = {
    id: 'experience',
    lightBg: false,
    lightText: true,
    darkText: false,

    title: 'Jan 2021 - Present',
    align_left: true,
    topline: "BioTEC Conference", // 
    headline: 'Web Platform Lead',
    description1: "After volunteering on BioTEC’s last in person conference to date in 2019, I joined the executive team in early 2021 as the solo Web Platform Lead. With a growing interest in both web development, design, and biotechnology, it was a perfect fit.",
    description2: "I completely designed and maintained the official website of the conference, and collaborated with multiple teams to produce, implement, and display forms of content. The conference had an astonishing 1100 global attendees, and the website continues to boast over 3000 monthly visits.",
    // description1: 'Completely redesigned the official website for the 2021 Biotechnology and Bioengineering Conference (BioTEC).',
    // description2: "Collaborated with multiple teams to produce, implement, and display forms of content to over 1100 global attendees.",
    
    imgStart: false,
    img: "./../images/img-biotec.jpg", 
    alt: 'BioTEC',

    buttonLabel: 'BioTEC Website',
    buttonLink: 'https://www.uwbiotec.ca'
}

export const experience_UWSquash = {
    id: 'experience',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    darkText: false,

    title: 'Sept 2019 - Present',
    align_left: false,
    topline: "Waterloo Squash team", // "University of waterloo varsity squash team",
    headline: 'Varsity Captain',
    description1: "In my rookie year, the Men’s Varsity Squash team went on to break a medal-less streak and win the Jester’s League while finishing 2nd at the OUA Championships.",
    description2: "Now, as one of the team’s captains, I lead practices and fitness sessions, organize recruitment efforts, plan fundraising efforts and more. I also work to produce multiple forms of content for the team’s social media platforms and have increased the following by 215% the past 2 years.",
    // description1: 'Commitment of over 15 hours/week for team practice, tournaments and squash committee meetings.',
    // description2: 'Responsibilities include leading practices and fitness sessions, organizing recruitment efforts, planning fundraising events, etc.',
    // description3: 'Produce multiple forms of media for the team’s various social media platforms; increased following by 215% over last 2 years.',
    
    imgStart: true,
    img: "./../images/img-squash3.jpeg", // squash->playing, squash2->intros
    alt: 'Squash',

    buttonLabel: 'Team Instagram Page',
    buttonLink: 'https://instagram.com/wloosquash'
}

export const experience_GE = {
    id: 'experience',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    darkText: false,

    title: 'July - Aug 2018',
    align_left: false,
    topline: "Hyderabad, India", // "University of waterloo varsity squash team",
    headline: 'Global Encounters',
    description1: "I travelled across the world to India along with 60 other students across 17 countries on a trip focused on service, leadership, and culture. I volunteered over 50 hours at a local government school and led debriefs for our service team to try and increase productivity.",
    description2: "We also got immersed into the beautiful culture and learned about the many revitalization projects that aim to maintain extraordinary historic sites across the country.",
    // description1: 'Participated in a service-learning trip with 60 students across 17 countries; focused on service, leadership and culture.',
    // description2: 'Volunteered over 50 hours at a local government school, and increased productivity by leading debriefs for our service team.',
    // description3: "Educated local students about the importance of personal hygiene through interactive presentations.",
    
    imgStart: false,
    img: "./../images/img-ge.jpg", 
    alt: 'GE',
}

export const project_Gaitmate = {
    id: 'projects',
    lightBg: true,
    lightText: true,
    darkText: false,

    title: 'May - Aug 2021',
    align_left: true,
    topline: "BME 261 Design Project",
    headline: 'GaitMate: Plantar Pressure Sensor with Real-Time Haptic Feedback',
    description1: 'Developed a system that transplanted plantar pressure data to haptic feedback, giving the user an understanding of the location and magnitude of the forces on their foot.',
    description2: 'Built a successful prototype that used force-resistive sensors, Arduino’s, MATLAB signal processing, and eccentric motors to send sensory information with low latency.',
    
    imgStart: true,
    img: "./../images/img-gaitmate.jpeg",
    alt: 'GaitMate',

    buttonLabel: '',
    buttonLink: ''
}

export const project_Joymouse = {
    id: 'projects',
    lightBg: false,
    lightText: true,
    darkText: false,

    title: 'Sept - Dec 2019',
    align_left: false,
    topline: "BME 161 Design Project",
    headline: 'Joymouse: Joystick Controlled Cursor for Individuals with Hand Tremors',
    description1: 'Created a functioning prototype that mechanically filters out unwanted cursor movement from hand tremors.',
    description2: 'Utilized Arduino coding for clicking and cursor movement on a computer.',
    // description3: 'Quantified the success of the device with 84% satisfaction of device comfortability', 
    description4: 'Effectively stabilized cursor movement and decreased average acceleration by 266%.',
    
    imgStart: false,
    img: "./../images/img-joymouse copy.png",
    alt: 'Joymouse',

    buttonLabel: 'Github',
    buttonLink: 'https://github.com/raziqkassam/joymouse'
}

export const experience_Lumiere = {
    id: 'experience', // for navigation
    lightBg: true, // background color
    lightText: true, // color of 
    darkText: false,

    align_left: true, // alignment of title and topline
    title: 'Sep 2017 - May 2018', // type of experience
    topline: "Junior Achievement", // time and location

    headline: 'Lumiére: Incorporating Circuits and LED Lights into Ordinary Greeting Cards', // job title and bullet points:
    description1: 'Elected as Vice President of Production within a student run company.',
    description2: "Designed functional prototypes that optimized resources while minimizing costs and environmental waste.",
    description3: 'Led efforts to investigate production methods, develop operational strategies, and provide training to ensure an efficient assembly line process.',
    // description4: 'Developed operational strategies to optimize resources in a time efficent manner.',
    // description4: 'Applied consumer feedback and adjusted product for future sales.',
    
    imgStart: true, // true -> img on right, false -> img on left
    img: "./../images/img-lumiere.jpg", // image location
    alt: 'Lumiere', // alternate text instead of image

}

export const project_WebDev = {
    id: 'projects',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    darkText: false,
}